/**
 * @typedef {object} Theme
 * @property {string} name
 * @property {[number,number,number,number]} color
 * @property {[number,number,number,number]} bg
 * @property {[number,number,number,number]} grid1
 * @property {[number,number,number,number]} grid2
 */

/** @type {Object.<string,Theme>} */
export const themes = {
  light: {
    name: 'Light',
    color: [0, 0.6, 1, 1],
    bg: [0.99, 0.99, 0.99, 1],
    grid1: [0, 0, 0, 0.2],
    grid2: [0, 0, 1, 0.1],
  },
  dark: {
    name: 'Dark',
    color: [1, 0.35, 0, 1],
    bg: [0.21, 0.2, 0.21, 1],
    grid1: [1, 1, 1, 0.3],
    grid2: [0.55, 0.55, 0.9, 0.25],
  }
}
